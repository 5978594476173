import cx from 'classnames';
import { Icons } from 'components/navigation/Components/Icons/Icons';
import { NavItem } from 'components/navigation/Components/NavItems/NavItem';
import { NavShortcut } from 'components/navigation/Components/NavItems/NavShortcut';
import { cookieDomains } from 'components/navigation/Utils/constants';
import { events } from 'components/navigation/Utils/events';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getConfig } from 'shared/config';
import { useScreenSize } from 'shared/utils/screenSize';
import { cookies } from 'shared/utils/storage/cookies';
import { featLoginWithCiamPing } from 'utils/feature-toggle/feat-login-with-ciam-ping';
import { identity } from 'utils/identity';
import loadable from 'utils/loadable';
import { LeftNavSubscriptions } from './LeftNavSubscriptions';
import { initLeftNav, leftNavBtn } from './initLeftNav';

const DevTools = loadable(() => import('./DevTools/DevTools'));

export const LeftNav = () => {
  const [isActive, setIsActive] = useState(false);
  const history = useHistory();
  const isMobile = useScreenSize('xs', 'sm');
  const isDesktopNavigation = useScreenSize('desktopNavigation');

  useEffect(() => {
    events.on('open', (side) => {
      if (side === 'nav-selfcare') setIsActive(true);
    });
    events.on('close', (side) => {
      if (side === 'nav-selfcare') setIsActive(false);
    });
    initLeftNav();
  }, []);

  useEffect(() => {
    if (isDesktopNavigation) {
      leftNavBtn?.open();
    } else {
      leftNavBtn?.close();
    }
  }, [isDesktopNavigation]);

  useEffect(() => {
    return events.on('link', (address) => {
      history.push(address);
      if (isMobile) {
        document.getElementById('navheaderButtonSelfcare')?.click();
      }
    });
  }, [history, isMobile]);

  return (
    <nav
      className={cx('navpanel navpanel--selfcare', { 'is-active': isActive })}
      id='nav-selfcare'>
      <div className='navpanel__body'>
        <a href='#start-of-content' className='navpanel__skip-to-content' id='skip-to-content'>
          Spring til indhold
        </a>
        <NavItems />
        <ul className='navshortcuts'>
          <NavShortcut
            link='https://yousee.dk/yousee-mail?icid=nav_left_webmail'
            icon='webmail'
            text='Webmail'
          />
          <NavShortcut
            link='https://play.yousee.dk/?icid=nav_left_tv'
            icon='tv-movie'
            text='YouSee Play'
          />
          <NavShortcut
            link='https://musik.yousee.dk/?icid=nav_left_musik'
            icon='music'
            text='Musik'
          />
        </ul>
      </div>
      <Icons />
    </nav>
  );
};

const NavItems: FC = () => {
  const { baseUrl, showDevTools } = getConfig().navigation ?? {};
  const navRef = useRef<HTMLUListElement>(null);

  if (!identity.isLoggedIn && !identity.isCheckingAuth && showDevTools) {
    return (
      <ul className='navpanel__items'>
        <DevTools />
      </ul>
    );
  }

  return (
    <ul className='navpanel__items' ref={navRef}>
      <li className='navpanel__item' data-type='user'>
        <a
          href={`${baseUrl}/indstillinger?icid=yss-leftmenu-userlink`}
          className='navpanel__item-link u-truncate'></a>
      </li>
      {showDevTools && <DevTools />}
      <NavItem icon='overview' link='/' text='Mine abonnementer' />
      <NavItem icon='bills' link='/regninger' text='Regninger &amp; betaling' />
      <NavItem icon='creditcard' link='/betalingsmetode' text='Betalingsmetode' />

      <LeftNavSubscriptions />

      <NavItem
        icon='mail-settings'
        link='/email'
        text='Mailindstillinger'
        data-type='emails'
        style={{ display: 'none' }}
      />
      <NavItem icon='settings' link='/indstillinger' text='Indstillinger' />
      {featLoginWithCiamPing() ? (
        <NavItem
          icon='logout'
          link={`${getConfig()?.ciam
            ?.host}/idp/startSLO.ping?TargetResource=https%3A%2F%2Fyousee.dk/`}
          text='Log af'
          onClick={logOutUser}
        />
      ) : (
        <NavItem
          icon='logout'
          link='https://id.yousee.dk/logout?timeout=3&amp;returnUrl=https://yousee.dk/'
          text='Log af'
          onClick={showDevTools ? logOutUser : undefined}
        />
      )}
    </ul>
  );
};

export const removeAuthCookies = () => {
  cookieDomains.forEach((domain) => {
    [
      'yspro',
      'TestUserName',
      'BcInstance',
      'YSProInstance',
      'KasiaInstance',
      'OssiInstance',
      'access_token',
      'refresh_token',
      'MailMicroserviceInstance',
    ].forEach((cookie) =>
      cookies.remove(cookie, {
        domain,
      }),
    );
  });
};

const logOutUser = () => {
  removeAuthCookies();
  location.reload();
};
